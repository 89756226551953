import React, { Fragment, useEffect, useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import FilterIcon from '@mui/icons-material/FilterAltOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Collapse, TableCell as MuiTableCell, TableRow as MuiTableRow, Typography, Stack, TableSortLabel, List, ListItem, ListItemButton } from '@mui/material';

import { isString } from '../utils';
import CustomModal from '../modals/CustomModal';


export function TypographyWrapper({ children, ...props }) {
  if (!isString(children)) return children
  if (!children) children = " " // if the typography is empty the link is not created
  return <Typography color='secondary' {...(props || {})}>{children}</Typography>
}


export  function LinkWrapper({ link, children }) {
  if (!link) return children
  return isString(link) ? <Link to={link} style={{ textDecoration: 'none', width: '100%' }}>{children}</Link> : <div onClick={link}>{children}</div>
}

const cellStyle = { padding: 8 }
const rowStyle = { '& > *': { borderBottom: 'unset' } }


export function TableCell({ row, col, collapsible, rowOpen, setRowOpen }) {
  const [active, setActive] = useState(false);
  const link = col.getLink ? col.getLink(row) : row.link
  return <MuiTableCell
    onMouseEnter={() => setActive(true)}
    onMouseLeave={() => setActive(false)}
    onClick={(!link && collapsible) ? () => setRowOpen(!rowOpen) : null}
    style={{ ...cellStyle, ...{ cursor: (collapsible || link) ? 'pointer' : 'default' } }} {...(col.rowprops || {})}>
    <LinkWrapper link={link}>
      <Stack direction="row" spacing={2}>
        <TypographyWrapper>{row[col.key] || ""}</TypographyWrapper>
        {active && col.onActive && col.onActive(row)}
      </Stack>
    </LinkWrapper>
  </MuiTableCell>
}


export function TableRow({ row, columns, collapsible }) {
  const [open, setOpen] = useState(false)
  return (
    <Fragment>
      <MuiTableRow sx={rowStyle}>
        {collapsible
          ? <MuiTableCell style={cellStyle} onClick={() => setOpen(!open)} >
            {open ? <KeyboardArrowUpIcon color='secondary' /> : <KeyboardArrowDownIcon color='secondary' />}
          </MuiTableCell>
          : null}
        {columns.map((col, cIdx) => <TableCell row={row} col={col} collapsible={collapsible} key={`table-row-col${cIdx}`} rowOpen={open} setRowOpen={setOpen} />)}
      </MuiTableRow>
      {
        collapsible
          ? <MuiTableRow>
            <MuiTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length + 2}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  {row.collapse || <></>}
                </Box>
              </Collapse>
            </MuiTableCell>
          </MuiTableRow>
          : null
      }
    </Fragment >
  );
}

TableRow.propTypes = {
};


export function EmptyTableRow({ emptyTableText, columns, collapsible }) {
  return (
    <MuiTableRow sx={rowStyle}>
      <MuiTableCell colSpan={columns.length + collapsible} style={cellStyle}>
        <TypographyWrapper align="center">{emptyTableText}</TypographyWrapper>
      </MuiTableCell>
    </MuiTableRow>
  );
}

EmptyTableRow.propTypes = {
};


function TableSort({ column, children }) {
  if (!column.sort) return children
  const active = column.sort.value != null
  const ascending = column.sort.value
  return <TableSortLabel active={active} direction={ascending ? 'asc' : 'desc'}
    sx={{
      '&.Mui-active .MuiTableSortLabel-icon': { color: 'secondary.main' },
      '& .MuiTableSortLabel-icon': { color: 'secondary.main', transform: ascending ? 'rotate(0deg) !important' : 'rotate(180deg) !important' },
    }}
    onClick={() => {
      const newAscending = (active) ? (ascending ? false : null) : true
      column.sort.setValue(newAscending)
    }}>
    {children}
  </TableSortLabel>
}



export function TableHeaderCell({ column }) {
  return (
    <MuiTableCell style={{ ...cellStyle, ...(column.headerprops?.style || {}) }}  {...(column.headerprops || {})}>
      <Stack direction='row' spacing={2}>
        <TypographyWrapper inline="true" fontWeight="bold">{column.name || ""}</TypographyWrapper>
        <TableFilter column={column} />
        <TableSort column={column}  />
      </Stack>
    </MuiTableCell>
  );
}

TableHeaderCell.propTypes = {
};




function FilterDialog({ options, value, setValue, isOpen, setIsOpen }) {

  const handleListItemClick = (_value) => {
    setValue(_value);
    setIsOpen(false)
  };

  const _options = options.map((o) => (typeof o === 'object') ? o : { label: o, value: o })

  return (
    <CustomModal title={'Filter'} isOpen={isOpen} setIsOpen={setIsOpen}>
      <List sx={{ pt: 0 }}>
        {_options.map((option) => (
          <ListItem disableGutters key={`option-${option.value}`} sx={(value === option.value) ? { borderColor: 'secondary.main', borderStyle: 'solid' } : {}}>
            <ListItemButton onClick={() => handleListItemClick(option.value)}>
              <TypographyWrapper>{option.label}</TypographyWrapper>
            </ListItemButton>
          </ListItem>
        ))}
        {value !== null && <ListItem disableGutters>
          <ListItemButton autoFocus onClick={() => handleListItemClick(null)} >
            <TypographyWrapper>{'Filter entfernen'}</TypographyWrapper>
          </ListItemButton>
        </ListItem>}
      </List>
    </CustomModal>
  );
}

export function TableFilter({ column, children }) {
  const [filterModalOpen, setFilterModalOpen] = useState(false)
  if (!column.filter) return children
  return <>
    <FilterDialog options={column.filter.options} value={column.filter.value} setValue={column.filter.setValue} isOpen={filterModalOpen} setIsOpen={setFilterModalOpen} />
    <TableSortLabel active={column.filter.value !== null} direction={'asc'}
      sx={{
        '&.Mui-active .MuiTableSortLabel-icon': { color: 'secondary.main' },
        '& .MuiTableSortLabel-icon': { color: 'secondary.main', transform: 'none !important' },
      }}
      IconComponent={FilterIcon}
      onClick={() => { setFilterModalOpen(true) }}>
      {children}
    </TableSortLabel>
  </>
}

