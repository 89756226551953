import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

import { CircularProgress, Grid, IconButton } from '@mui/material';

import { datetime2FormattedString, string2Datetime } from '../../../react_utils/utils';
import ClientConfigureFormModal from '../clients/ClientConfigureFormModal';
import AddClientModal from './AddClientModal';
import Table from '../../../react_utils/table/Table';
import Icon from '../../../react_utils/Icon';
import { sshTunnel } from '../clients/sshTunnel';
import CustomSnackbar from '../../../react_utils/CustomSnackbar';


export default function LocationClients({ location, resetParent, session }) {

  const [chosenClient, setChosenClient] = useState(null)

  // chosen device for ssh tunnel
  const [chosenSshClientIds, setChosenSshClientIds] = useState([])

  // messaging
  const [snackbar, setSnackbar] = useState({ open: false, msg: "", severity: "success" })

  const _sshTunnel = async (clientId, event) => {
    setChosenSshClientIds(l => [...l, clientId])
    sshTunnel(
      clientId,
      session,
      () => setSnackbar(s => ({ ...s, open: true, msg: "Der SSH-Tunnel wurde geöffnet.", severity: 'success' })),
      () => setSnackbar(s => ({ ...s, open: true, msg: "Der SSH-Tunnel konnte nicht geöffnet werden.", severity: 'error' })),
      () => setChosenSshClientIds(l => l.filter(i => i !== clientId)),
      event.ctrlKey
    )
  }

  if (!location) return null


  return (
    <Grid xs={12} sm={12} md={12} item justifyContent="center" alignItems="center">
      {/* <ClientLocationFormModal
        session={session}
        resetParent={resetParent}
        client={chosenClient}
        isOpen={!!chosenClient && !chosenClient.location}
        setIsOpen={(isOpen) => { if (!isOpen) setChosenClient(null) }} /> */}
      <AddClientModal
        location={location}
        resetParent={resetParent}
        session={session}
      />
      <ClientConfigureFormModal
        session={session}
        resetParent={resetParent}
        client={chosenClient}
        isOpen={!!chosenClient}
        setIsOpen={(isOpen) => { if (!isOpen) setChosenClient(null) }} />
      <Table
        columns={[
          { name: 'Geräte-ID', key: 'id' },
          { name: 'Zuletzt online', key: 'timestamp' },
          ...session.user.is_superuser ? [{
            name: '', key: 'ssh', headerprops: { style: { width: 60 } },
            onActive: (row) => !chosenSshClientIds.includes(row.client.id) && <IconButton sx={{ p: 0 }} disableFocusRipple disableRipple style={{ backgroundColor: 'transparent' }} onClick={(e) => _sshTunnel(row.client.id, e)}>
              <Icon icon={'ssh'} sx={{ color: 'secondary.main' }} fontSize='small' />
            </IconButton>,
            getLink: () => null
          }] : [],
        ]}
        rows={location.impulsclient_set.map(client => ({
          id: client.serial,
          timestamp: datetime2FormattedString(string2Datetime(client.latest_query_timestamp)),
          link: () => setChosenClient(client),
          ssh: chosenSshClientIds.includes(client.id) && <CircularProgress sx={{ color: 'secondary.main' }} size="20px" />,
          client: client
        }))}
      />
      <CustomSnackbar message={snackbar.msg} duration={3000} severity={snackbar.severity} open={snackbar.open} setIsOpen={(isOpen) => setSnackbar(s => ({ ...s, open: isOpen }))} />
    </Grid>
  )
}

LocationClients.propTypes = {
  location: PropTypes.object,
  session: PropTypes.object
}