import React, { Fragment } from 'react';

import { PropTypes } from 'prop-types';

import 'dayjs/locale/de';
import TabView from "../../react_utils/TabView";

import HourlyFlow from './flowhistory/HourlyFlow'
import DailyFlow from './flowhistory/DailyFlow'
import MonthlyFlow from './flowhistory/MonthlyFlow'
import { month2String, date2String, year2String } from '../../react_utils/utils';
import { API_URL_cDAILY_FLOW, API_URL_cHOURLY_FLOW, API_URL_cMONTHLY_FLOW } from '../../urls';

export default function FlowHistory({ session }) {


  return (
    <Fragment>
      {/* <Typography color='primary'>
        Die Flusshistorie wird aus der 5-minütigen Abtastung der Anlagenwerte ermittelt und ist somit
        lediglich eine Schätzung, da die Werte innerhalb des 5-Minuten-Intervals Schwankungen unterliegen.
        Daher können die genauen Werte abweichen.
      </Typography> */}
      <TabView
        components={[
          {
            title: 'Tag',
            getChild: () => <HourlyFlow
              getFlow={(date) => session.backendClient.get(API_URL_cHOURLY_FLOW, { params: { date: date2String(date), sync: false } }).then((res) => res.data)}
              syncFlow={(date) => session.backendClient.get(API_URL_cHOURLY_FLOW, { params: { date: date2String(date), sync: true } }).then((res) => res.data)}
              session={session}
            />
          },
          {
            title: 'Monat',
            getChild: () => <DailyFlow
              getFlow={(date) => session.backendClient.get(API_URL_cDAILY_FLOW, { params: { month: month2String(date) } }).then((res) => res.data)}
              session={session}
            />
          },
          {
            title: 'Jahr',
            getChild: () => <MonthlyFlow
              getFlow={(date) => session.backendClient.get(API_URL_cMONTHLY_FLOW, { params: { year: year2String(date) } }).then((res) => res.data)}
              session={session}
            />
          }
        ]}
      />
    </Fragment >
  )
}

FlowHistory.propTypes = {
  session: PropTypes.object
}
