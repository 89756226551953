import React, { Fragment, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';

import { Grid, Stack } from '@mui/material';
import { addNDays, string2Datetime } from '../../../react_utils/utils';
import LoadingPage from '../../../react_utils/LoadingPage';
import CustomPlot from '../../../react_utils/plots/Plot';
import DatePicker from '../../../react_utils/DatePicker';
import { LoadHistoryTitle, ProductionHistoryTitle, getBatterySoCFlow, getColorByKey, getConsumptionFlow, getPowerFlow, getProductionFlow } from './flowhistoryutils';
import HourlyFlowDownloader from './HourlyFlowDownloader';

export default function HourlyFlow({ getFlow, syncFlow, session }) {
  const [date, setDate] = useState(addNDays(new Date(), -1))
  const [flowHistory, setFlowHistory] = useState(null)

  useEffect(() => {
    getFlow(date).then(res => setFlowHistory(res))
      .then(() => {
        const cDate = date
        syncFlow(date).then((res) => { if (date === cDate) setFlowHistory(res) })
      })
  }, [date])

  if (flowHistory === null) return <LoadingPage />

  const time = flowHistory.time.map(t => string2Datetime(t))
  // const plotheight = (isMobileOnly) ? "350px" : "600px";
  const powerFlow = getPowerFlow(flowHistory)
  const productionFlow = getProductionFlow(flowHistory)
  const consumptionFlow = getConsumptionFlow(flowHistory)
  const batterySoCFlow = getBatterySoCFlow(flowHistory)


  return (
    <Fragment>
      <Grid container justify="center">
        <DatePicker date={date} setDate={setDate} sx={{ marginBottom: '20px' }} />
        <Grid xs={12} sm={12} md={12} item justifyContent="center" alignItems="center">
          <Stack direction="column" spacing={5}>
            <CustomPlot xlabel='Zeit' ylabel={"Leistung [kW]"} type='line'
              title={"Leistungsflüsse"}
              datasets={
                Object.entries(powerFlow).map(([key, label]) => ({
                  label: label,
                  data: flowHistory[key].map((p, idx) => ({ x: time[idx], y: p })),
                  borderColor: getColorByKey(key),
                  fill: false,
                  backgroundColor: getColorByKey(key, "0.8"),
                  pointRadius: 0,
                }))}
              xScaleOptions={{
                type: 'time',
                time: {
                  displayFormats: { hour: 'HH' },
                  tooltipFormat: 'dd.MM.yyyy HH:mm',
                  unit: 'minute',
                }
              }}
              xTickOptions={{ stepSize: 5 }}
              stacked={false}
            />
            {(Object.keys(consumptionFlow).length > 0) ?
              <CustomPlot xlabel='Zeit' ylabel={"Leistung [kW]"} type='line'
                title={<ProductionHistoryTitle timeHorizon="hourly" />}
                datasets={
                  Object.entries(productionFlow).map(([key, label]) => ({
                    label: label,
                    data: flowHistory[key].map((p, idx) => ({ x: time[idx], y: p })),
                    borderColor: getColorByKey(key),
                    fill: true,
                    backgroundColor: getColorByKey(key, "1"),
                    pointRadius: 0,
                  }))}
                xScaleOptions={{
                  type: 'time',
                  time: {
                    displayFormats: { hour: 'HH' },
                    tooltipFormat: 'dd.MM.yyyy HH:mm',
                    unit: 'minute',
                  }
                }}
                xTickOptions={{ stepSize: 5 }}
                stacked={true}
              /> : null}
            {(Object.keys(consumptionFlow).length > 0)
              ? <CustomPlot xlabel='Zeit' ylabel={"Leistung [kW]"} type='line'
                title={<LoadHistoryTitle timeHorizon="hourly" />}
                datasets={
                  Object.entries(consumptionFlow).map(([key, label]) => ({
                    label: label,
                    data: flowHistory[key].map((p, idx) => ({ x: time[idx], y: p })),
                    borderColor: getColorByKey(key),
                    fill: true,
                    backgroundColor: getColorByKey(key, "1"),
                    pointRadius: 0,
                  }))}
                xScaleOptions={{
                  type: 'time',
                  time: {
                    displayFormats: { hour: 'HH' },
                    tooltipFormat: 'dd.MM.yyyy HH:mm',
                    unit: 'minute',
                  }
                }}
                xTickOptions={{ stepSize: 5 }}
                stacked={true}
              />
              : null}

            {(Object.keys(batterySoCFlow).length > 0)
              ? <CustomPlot xlabel='Zeit' ylabel={"Ladezustand [%]"} type='line'
                title={"Batterie-Ladezustand"}
                datasets={
                  Object.entries(batterySoCFlow).map(([key, label]) => ({
                    label: label,
                    data: flowHistory[key].map((p, idx) => ({ x: time[idx], y: p })),
                    borderColor: getColorByKey(key),
                    fill: true,
                    backgroundColor: getColorByKey(key, "0.8"),
                    pointRadius: 0,
                  }))}
                xScaleOptions={{
                  type: 'time',
                  time: {
                    displayFormats: { hour: 'HH' },
                    tooltipFormat: 'dd.MM.yyyy HH:mm',
                    unit: 'minute',
                  }
                }}
                xTickOptions={{ stepSize: 5 }}
                stacked={false}
                axRanges={{ minY: 0, maxY: 100 }}
              />
              : null}
          </Stack>
        </Grid>
        {/* PIE CHARTS */}
        {/* <Grid container justify="center">
          <Grid xs={12} sm={6} md={6} item justifyContent="center" alignItems="center">
            {(Object.keys(consumptionFlow).length > 0)
              ? <CustomPlot xlabel='Zeit' ylabel={"Leistung [kW]"} type='line'
                title={"Deckung des Verbrauchs"}
                datasets={
                  Object.entries(consumptionFlow).map(([key, label]) => ({
                    label: label,
                    data: flowHistory[key].map((p, idx) => ({ x: time[idx], y: p })),
                    borderColor: getColorByKey(key),
                    fill: true,
                    backgroundColor: getColorByKey(key, "1"),
                    pointRadius: 0,
                  }))}
                xScaleOptions={{
                  type: 'time',
                  time: {
                    displayFormats: { hour: 'HH' }
                  }
                }}
                stacked={true}
                height={plotheight * 3}
              />
              : null}
          </Grid>
          <Grid xs={12} sm={6} md={6} item justifyContent="center" alignItems="center">

          </Grid>
        </Grid> */}
        <Grid item xs={12} sm={12} md={12} sx={{ paddingY: 10 }}>
          <HourlyFlowDownloader defaultDate={date} getSyncedFlow={syncFlow} session={session} />
        </Grid>
      </Grid>

    </Fragment >

  );
}

HourlyFlow.propTypes = {
  session: PropTypes.object
}
