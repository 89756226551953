import React, { Fragment, useEffect, useState } from 'react'

import { PropTypes } from 'prop-types'

import { Grid, Typography, Stack, Box } from '@mui/material'

import LoadingPage from '../../react_utils/LoadingPage'
import InfoTooltip from '../../react_utils/InfoTooltip'
import { API_URL_cWALLBOXCHARGINGHISTORY } from '../../urls'
import ChargingHistoryTable from './wallbox/ChargingHistoryTable'
import ExcelExport from '../../react_utils/ExcelExport'
import { date2FormattedString, month2String } from '../../react_utils/utils'
import DatePicker from '../../react_utils/DatePicker'
import TablePagination from '../../react_utils/table/TablePagination'

export default function ChargingHistory({ session }) {
  const [chargingHistory, setChargingHistory] = useState(null)
  const [date, setDate] = useState(new Date())

  const [batch, setBatch] = useState(0)
  const [nPages, setNPages] = useState(null)
  const batchSize = 20

  const getHistory = () => {
    const params = { ascending_ids: false, batch, batch_size: batchSize }
    session.backendClient.get(API_URL_cWALLBOXCHARGINGHISTORY, { params }).then((res) => {
      setChargingHistory(res.data)
      setNPages(Number(res.headers.length))
    })
  }

  useEffect(() => {
    getHistory()
  }, [batch])

  const getExcelData = async () => {
    return session.backendClient.get(API_URL_cWALLBOXCHARGINGHISTORY, { params: { month: month2String(date) } }).then((res) => {
      const history = res.data
      if (history.length > 0) {
        return Object.values(Object.groupBy(history, ({ wallbox }) => wallbox)).map((wbHistory) => {
          const wallboxName = wbHistory[0].wallbox_name
          return createExcelSheet(wallboxName, wbHistory)
        })
      } else { return [createExcelSheet('Ladehistorie', history)] }
    })
  }

  const createExcelSheet = (sheetName, chargingHistoryToDownload) => {
    const excelRows = [['Datum', 'Lademenge [kWh]', 'Ladedauer [Std]', 'Ladestart', 'Ladeende']]
    chargingHistoryToDownload.forEach((info) => {
      excelRows.push([
        date2FormattedString(info.start_time.slice(0, 10)),
        info.energy ? info.energy.toFixed(2) : '0',
        info.duration,
        info.start_time.slice(11, 16),
        info.end_time ? info.end_time.slice(11, 16) : ''
      ])
    })
    return { name: sheetName, data: excelRows }
  }

  if (chargingHistory === null) return <LoadingPage />


  return (
    <Fragment>
      <div className="text-center">
        <Typography sx={{ marginY: '10px' }}>
          Ladevorgänge <InfoTooltip>
            In der Ladehistorie können Sie die Ladevorgänge Ihres E-Autos einsehen.
            Bitte beachten Sie, dass es sich bei der Lademenge lediglich um eine Hochrechnung handelt, falls Sie keinen separaten Zähler für die Wallbox haben, der an Energieimpuls angeschlossen ist.
          </InfoTooltip>
        </Typography>
      </div>
      <Grid container justify="center" sx={{ marginBottom: '50px' }}>
        <Grid xs={12} sm={12} md={12} item justifyContent="center" alignItems="center">
          <ChargingHistoryTable session={session} chargingHistory={chargingHistory} />
        </Grid>
        <Grid xs={12} sm={12} md={12} item paddingTop={"10px"}>
          <TablePagination
            nPages={nPages}
            page={batch}
            setPage={setBatch}
          />
        </Grid>
      </Grid>
      <Typography sx={{ marginBottom: '20px' }}>Ladehistorie als Exceldatei exportieren:</Typography>
      <Stack direction="row" spacing={2}>
        <DatePicker date={date} setDate={setDate} views={["month", "year"]} />
        <ExcelExport
          exporter={getExcelData}
          fileName={`Ladehistorie-${date ? month2String(date) : ''}`}
          disabled={!date}
        />
      </Stack>
    </Fragment>
  )
}

ChargingHistory.propTypes = {
  session: PropTypes.object
}
