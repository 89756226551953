import { checkIfValueIsEmpty, isValidEmailAddress } from "../../../react_utils/utils"

export const getClientLocationAssignmentFormTemplate = (setForm, location) => [
  {
    type: 'input_number',
    key: 'client_id',
    name: 'client_id',
    width: '50%',
    hide: true
  },
  {
    type: 'input_number',
    key: 'location_id',
    name: 'location_id',
    width: '50%',
    optional: true,
    hide: true
  },
  {
    type: 'address',
    key: 'street_and_number',
    name: 'Straße Nr.',
    width: '50%',
    disabled: !!location,
    selectAddress: (addr) => setForm(f => ({...f, street_and_number: addr.street_and_number, zip_and_city: addr.zip_and_city}))
  },
  {
    type: 'input_chars',
    key: 'zip_and_city',
    name: 'PLZ Ort',
    disabled: true,
    width: '50%',
  },
  {
    type: 'input_chars',
    key: 'name',
    name: 'Kunde',
    disabled: !!location,
    width: '50%',
    optional: true
  },
]

export const getUserFormTemplate = () => [
  {
    type: 'input_chars',
    key: 'username',
    name: 'Benutzername',
    width: '50%',
    disabled: true
  },
  {
    type: 'input_chars',
    key: 'first_name',
    name: 'Vorname',
    width: '50%',
  },
  {
    type: 'input_chars',
    key: 'last_name',
    name: 'Nachname',
    width: '50%',
  },
  {
    type: 'input_chars',
    key: 'email',
    name: 'E-Mail',
    width: '50%',
    isEmpty: (value) => checkIfValueIsEmpty(value) || !isValidEmailAddress(value),
  },
]
