import React, { Fragment, useState, useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import { PropTypes } from 'prop-types';

import { Grid } from '@mui/material';

import CustomerFormModal from './CustomerFormModal';
import { string2FormattedString, useDidMountEffect } from '../../../react_utils/utils';
import LoadingPage from '../../../react_utils/LoadingPage';
import { API_URL_CUSTOMER } from '../../../urls';
import CharsInput from '../../../react_utils/fields/CharsInput';
import Table from '../../../react_utils/table/Table';
import TablePagination from '../../../react_utils/table/TablePagination';
import Icon from '../../../react_utils/Icon';


// const cardWidth = 350;
// // const cardHeight = (isMobileOnly) ? 150 : 200;
// const cardSpace = (isMobileOnly) ? 2 : 4;
// const direction = (isMobileOnly) ? "column" : "row";

export default function Customers({ session }) {
  const [customers, setCustomers] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [chosenCustomer, setChosenCustomer] = useState(null)


  // pagination
  const [batch, setBatch] = useState(0)
  const [nPages, setNPages] = useState(null)
  const batchSize = 20


  // search filter
  const [searchInput, setSearchInput] = useState('')

  useEffect(() => {
    resetState();
  }, [])

  useEffect(() => {
    if (!loaded) return // run if 'searchInput' changes, but not on initial render
    const delayDebounceFn = setTimeout(() => {
      if (batch !== 0) setBatch(0)
      else getCustomers()
    }, 1500)

    return () => clearTimeout(delayDebounceFn)
  }, [searchInput])

  useDidMountEffect(() => { // run if 'batch' changes, but not on initial render
    resetState()
  }, [batch])

  const resetState = () => {
    setLoaded(false)
    getCustomers().then(() => setLoaded(true))
  }

  const getCustomers = async () => {
    const params = {
      batch_size: batchSize,
      batch,
      search: (searchInput) || null,
      ascending_ids: false,
    }
    return session.backendClient.get(API_URL_CUSTOMER, { params })
      .then(res => { setNPages(Number(res.headers.length)); setCustomers(res.data) })
  }

  return (
    <Fragment>
      {(!loaded) ? <LoadingPage /> : null}
      <Grid container justify="center">
        <Grid xs={12} sm={12} md={6} item >
          <CustomerFormModal
            resetParent={resetState}
            session={session}
          />
          <CustomerFormModal
            resetParent={resetState}
            session={session}
            customer={chosenCustomer}
            isOpen={!!chosenCustomer}
            setIsOpen={(isOpen) => { if (!isOpen) setChosenCustomer(null) }}
            getOpenButton={() => null}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6} item display="flex" justifyContent="flex-end" >
          <CharsInput
            label="Suche"
            onChange={v => setSearchInput(v)}
          />
        </Grid>
        <Grid xs={12} sm={12} md={12} item justifyContent="center" alignItems="center">
          {customers
            ? <Table
              columns={[
                { name: 'Nachname', key: 'last_name' },
                { name: 'Vorname', key: 'first_name' },
                { name: 'Nutzer', key: 'username' },
                { name: 'Standort', key: 'location' },
                { name: '', key: 'location_link', getLink: () => null },
                { name: 'E-Mail', key: 'email' },
              ]}
              rows={customers.map(customer => ({
                // key: `${customer.id}`,
                first_name: customer.first_name,
                last_name: customer.last_name,
                username: customer.username,
                email: customer.email,
                date_joined: string2FormattedString(customer.date_joined),
                link: (row) => { setChosenCustomer(customer) },
                // address: customer._location?.address || ''
                location_link: customer.location && <Link to={`/standort/${customer.location}`} style={{ textDecoration: 'none', width: '100%' }}>
                  <Icon icon={'location'} sx={{ color: 'secondary.main' }} fontSize='small' />
                </Link>,
                location: customer._location?.name || customer._location?.address || '',
              }))}
            />
            : null}
        </Grid>
        <Grid xs={12} sm={12} md={12} item display="flex" justifyContent="center" paddingTop={"10px"}>
          <TablePagination
            nPages={nPages}
            page={batch}
            setPage={setBatch}
          />
        </Grid>
      </Grid>
    </Fragment>
  )
}

Customers.propTypes = {
  session: PropTypes.object
}