import React, { Fragment, useEffect, useState } from 'react'

import { PropTypes } from 'prop-types'

import { Grid } from '@mui/material'


import LoadingPage from '../../../react_utils/LoadingPage'
import { API_URL_WALLBOXCHARGINGHISTORY } from '../../../urls'
import ChargingHistoryTable from '../wallbox/ChargingHistoryTable'
import TablePagination from '../../../react_utils/table/TablePagination'

export default function ChargingHistoryTab({ location, session }) {
  const [chargingHistory, setChargingHistory] = useState(null)

  const [batch, setBatch] = useState(0)
  const [nPages, setNPages] = useState(null)
  const batchSize = 20

  useEffect(() => {
    if (!location) return
    const params = {
      wallbox__location: location.id,
      ascending_ids: false,
      batch,
      batch_size: batchSize
    }
    session.backendClient.get(API_URL_WALLBOXCHARGINGHISTORY, { params }).then((res) => {
      setChargingHistory(res.data)
      setNPages(res.headers.length)
    })

  }, [location, batch])

  if (chargingHistory === null) return <LoadingPage />


  return (
    <Fragment>
      <Grid container justify="center">
        <Grid xs={12} sm={12} md={12} item justifyContent="center" alignItems="center">
          <ChargingHistoryTable session={session} chargingHistory={chargingHistory} />
        </Grid>
        <Grid xs={12} sm={12} md={12} item paddingTop={"10px"}>
          <TablePagination
            nPages={nPages}
            page={batch}
            setPage={setBatch}
          />
        </Grid>
      </Grid>
    </Fragment>
  )
}

ChargingHistoryTab.propTypes = {
  session: PropTypes.object
}
