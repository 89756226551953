import React, { Fragment, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';

import { Grid, Stack } from '@mui/material';

import { string2Datetime } from '../../../react_utils/utils';
import LoadingPage from '../../../react_utils/LoadingPage';
import CustomPlot from '../../../react_utils/plots/Plot';
import DatePicker from '../../../react_utils/DatePicker';
import { LoadHistoryTitle, ProductionHistoryTitle, getBatterySoCFlow, getColorByKey, getConsumptionFlow, getPowerFlow, getProductionFlow } from './flowhistoryutils';
import MonthlyFlowDownloader from './MonthlyFlowDownloader';

export default function MonthlyFlow({ getFlow, session }) {
  //const [year, setYear] = useState((new Date()).getFullYear())
  const [date, setDate] = useState(new Date())
  const [flowHistory, setFlowHistory] = useState(null)


  useEffect(() => {
    getFlow(date).then(res => setFlowHistory(res))
  }, [date])

  if (flowHistory === null) return <LoadingPage />

  const time = flowHistory.time.map(t => string2Datetime(t))
  const powerFlow = getPowerFlow(flowHistory)
  const productionFlow = getProductionFlow(flowHistory)
  const consumptionFlow = getConsumptionFlow(flowHistory)
  const batterySoCFlow = getBatterySoCFlow(flowHistory)

  return (
    <Fragment>
      <Grid container justify="center">
        <DatePicker date={date} setDate={setDate} views={["year"]} sx={{ marginBottom: '20px' }} />
        <Grid xs={12} sm={12} md={12} item justifyContent="center" alignItems="center">
          <Stack direction="column" spacing={5}>
            <CustomPlot xlabel='Zeit' ylabel={"Energie [kWh]"} type='bar'
              title={"Leistungsflüsse"}
              datasets={
                Object.entries(powerFlow).map(([key, label]) => ({
                  label: label,
                  data: flowHistory[key].map((p, idx) => ({ x: time[idx], y: p })),
                  borderColor: getColorByKey(key),
                  fill: false,
                  backgroundColor: getColorByKey(key, "0.8"),
                  pointRadius: 0,
                }))}
              xScaleOptions={{
                type: 'time',
                time: {
                  displayFormats: { hour: 'HH' },
                  tooltipFormat: 'MM.yyyy',
                  minUnit: 'month',
                }
              }}
              stacked={false}
            />
            {(Object.keys(consumptionFlow).length > 0) ?
              <CustomPlot xlabel='Zeit' ylabel={"Energie [kWh]"} type='bar'
                title={<ProductionHistoryTitle timeHorizon="monthly" />}
                datasets={
                  Object.entries(productionFlow).map(([key, label]) => ({
                    label: label,
                    data: flowHistory[key].map((p, idx) => ({ x: time[idx], y: p })),
                    borderColor: getColorByKey(key),
                    fill: true,
                    backgroundColor: getColorByKey(key, "1"),
                    pointRadius: 0,
                  }))}
                xScaleOptions={{
                  type: 'time',
                  time: {
                    displayFormats: { hour: 'HH' },
                    tooltipFormat: 'MM.yyyy',
                    minUnit: 'month'
                  }
                }}
                stacked={true}
              /> : null}
            {(Object.keys(consumptionFlow).length > 0)
              ? <CustomPlot xlabel='Zeit' ylabel={"Energie [kWh]"} type='bar'
                title={<LoadHistoryTitle timeHorizon="monthly" />}
                datasets={
                  Object.entries(consumptionFlow).map(([key, label]) => ({
                    label: label,
                    data: flowHistory[key].map((p, idx) => ({ x: time[idx], y: p })),
                    borderColor: getColorByKey(key),
                    fill: true,
                    backgroundColor: getColorByKey(key, "1"),
                    pointRadius: 0,
                  }))}
                xScaleOptions={{
                  type: 'time',
                  time: {
                    displayFormats: { hour: 'HH' },
                    tooltipFormat: 'MM.yyyy',
                    minUnit: 'month'
                  }
                }}
                stacked={true}
              />
              : null}

            {/* {(Object.keys(batterySoCFlow).length > 0)
              ? <CustomPlot xlabel='Zeit' ylabel={"Ladezustand [%]"} type='bar'
                title={"Batterie-Ladezustand"}
                datasets={
                  Object.entries(batterySoCFlow).map(([key, label]) => ({
                    label: label,
                    data: flowHistory[key].map((p, idx) => ({ x: time[idx], y: p })),
                    borderColor: getColorByKey(key),
                    fill: true,
                    backgroundColor: getColorByKey(key, "0.8"),
                    pointRadius: 0,
                  }))}
                xScaleOptions={{
                  type: 'time',
                  time: {
                    displayFormats: { hour: 'HH' },
                  tooltipFormat: 'MM.yyyy',
                  minUnit: 'month'
                  }
                }}
                stacked={false}
                height={plotheight}
                axRanges={{ minY: 0, maxY: 100 }}
              />
              : null} */}
          </Stack>
        </Grid>
        {/* PIE CHARTS */}
        {/* <Grid container justify="center">
          <Grid xs={12} sm={6} md={6} item justifyContent="center" alignItems="center">
            {(Object.keys(consumptionFlow).length > 0)
              ? <CustomPlot xlabel='Zeit' ylabel={"Leistung [kW]"} type='line'
                title={"Deckung des Verbrauchs"}
                datasets={
                  Object.entries(consumptionFlow).map(([key, label], idx) => ({
                    label: label,
                    data: flowHistory[key].map((p, idx) => ({ x: time[idx], y: p })),
                    borderColor: getColorByKey(key),
                    fill: true,
                    backgroundColor: getColorByKey(key, "1"),
                    pointRadius: 0,
                  }))}
                xScaleOptions={{
                  type: 'time',
                  time: {
                    displayFormats: { hour: 'HH' },
                  tooltipFormat: 'MM.yyyy',
                  }
                }}
                stacked={true}
                height={plotheight * 3}
              />
              : null}
          </Grid>
          <Grid xs={12} sm={6} md={6} item justifyContent="center" alignItems="center">

          </Grid>
        </Grid> */}
        <Grid item xs={12} sm={12} md={12} sx={{ paddingY: 10 }}>
          <MonthlyFlowDownloader defaultDate={date} getSyncedFlow={getFlow} session={session} />
        </Grid>
      </Grid>
    </Fragment >

  );
}

MonthlyFlow.propTypes = {
  session: PropTypes.object
}
